import { RuntimeConfig } from "nuxt/schema";

const title = "Frontiers | Science news";
const description =
  "The latest science & research news from Frontiers - Publishing the most cited open-access journals in their fields";

const homePageLDJson = (config: RuntimeConfig) => {
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "BreadcrumbList",
        "@id": `${config.public.baseUrl}/news/#breadcrumblist`,
        "itemListElement": [
          {
            "@type": "ListItem",
            "@id": `${config.public.baseUrl}/news/#listItem`,
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": `${config.public.baseUrl}/news/`,
              "name": "Science News",
              "description": description,
              "url": `${config.public.baseUrl}/news/`
            }
          }
        ]
      },
      {
        "@type": "Blog",
        "@id": `${config.public.baseUrl}/news/#blog`,
        "name": title,
        "url": `${config.public.baseUrl}/news/`
      },
      {
        "@type": "WebPage",
        "@id": `${config.public.baseUrl}/news/#webpage`,
        "url": `${config.public.baseUrl}/news/`,
        "name": title,
        "inLanguage": "en-US",
        "isPartOf": {
          "@id": `${config.public.baseUrl}/#website`
        }
      },
      {
        "@type": "WebSite",
        "@id": `${config.public.baseUrl}/news/#website`,
        "url": `${config.public.baseUrl}/news/`,
        "name": title,
        "description": description,
        "inLanguage": "en-US",
        "publisher": {
          "@id": `${config.public.baseUrl}/#organization`
        },
        "potentialAction": {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": `${config.public.baseUrl}/news/all?query={search_term_string}`
          },
          "query-input": "required name=search_term_string"
        }
      }
    ]
  };
};

interface IPostListPageLDJson {
  slug: string;
  page: string;
}

const postListPageLDJson = (
  config: RuntimeConfig,
  options: IPostListPageLDJson
) => {
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "BreadcrumbList",
        "@id": `${config.public.baseUrl}/news/${options.page}/${options.slug}/#breadcrumblist`,
        "itemListElement": [
          {
            "@type": "ListItem",
            "@id": `${config.public.baseUrl}/news/#listItem`,
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": `${config.public.baseUrl}/news/`,
              "name": "Home",
              "description": description,
              "url": `${config.public.baseUrl}/news/`
            },
            "nextItem": `${config.public.baseUrl}/news/${options.page}/${options.slug}/#listItem`
          },
          {
            "@type": "ListItem",
            "@id": `${config.public.baseUrl}/news/${options.page}/${options.slug}/#listItem`,
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": `${config.public.baseUrl}/news/${options.page}/${options.slug}/`,
              "name": options.slug,
              "url": `${config.public.baseUrl}/news/${options.page}/${options.slug}/`
            },
            "previousItem": `${config.public.baseUrl}/news/#listItem`
          }
        ]
      },
      {
        "@type": "CollectionPage",
        "@id": `${config.public.baseUrl}/news/${options.page}/${options.slug}/#collectionpage`,
        "url": `${config.public.baseUrl}/news/${options.page}/${options.slug}/`,
        "name": `${title} - ${options.slug}`,
        "inLanguage": "en-US",
        "isPartOf": {
          "@id": `${config.public.baseUrl}/news/#website`
        },
        "breadcrumb": {
          "@id": `${config.public.baseUrl}/news/${options.page}/${options.slug}/#breadcrumblist`
        }
      },
      {
        "@type": "Blog",
        "@id": `${config.public.baseUrl}/news/#blog`,
        "name": title,
        "url": `${config.public.baseUrl}/news/`
      },
      {
        "@type": "WebSite",
        "@id": `${config.public.baseUrl}/news/#website`,
        "url": `${config.public.baseUrl}/news/`,
        "name": title,
        "description": description,
        "inLanguage": "en-US",
        "publisher": {
          "@id": `${config.public.baseUrl}/news/#organization`
        }
      }
    ]
  };
};

interface IPostPageLDJson {
  slug: string;
  author: string;
  authorSlug: string;
  featuredImageUrl: string;
  datePublished: string;
  dateModified: string;
  relatedSubjects: string;
  title: string;
  description: string;
}

const postPageLDJson = (config: RuntimeConfig, options: IPostPageLDJson) => {
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "BlogPosting",
        "@id": `${config.public.baseUrl}/news/${options.slug}/#blogposting`,
        "name": `${options.title} - ${title}`,
        "headline": options.title,
        "author": {
          "@id": `${config.public.baseUrl}/news/author/${options.authorSlug}/#author`
        },
        "publisher": { "@id": `${config.public.baseUrl}/news/#organization` },
        "image": {
          "@type": "ImageObject",
          "url": options.featuredImageUrl
        },
        "datePublished": options.datePublished,
        "dateModified": options.dateModified,
        "inLanguage": "en-US",
        "mainEntityOfPage": {
          "@id": `${config.public.baseUrl}/news/${options.slug}/#webpage`
        },
        "isPartOf": {
          "@id": `${config.public.baseUrl}/news/${options.slug}/#webpage`
        },
        "articleSection": options.relatedSubjects
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${config.public.baseUrl}/news/${options.slug}/#breadcrumblist`,
        "itemListElement": [
          {
            "@type": "ListItem",
            "@id": `${config.public.baseUrl}/news/${options.slug}/#listItem`,
            "position": 5,
            "item": {
              "@type": "WebPage",
              "@id": `${config.public.baseUrl}/news/${options.slug}`,
              "name": options.title,
              "description": options.description,
              "url": `${config.public.baseUrl}/news/${options.slug}`
            }
          }
        ]
      },
      {
        "@type": "Blog",
        "@id": `${config.public.baseUrl}/news/#blog`,
        "name": title,
        "url": `${config.public.baseUrl}/news/`
      },
      {
        "@type": "Person",
        "@id": `${config.public.baseUrl}/news/author/${options.authorSlug}/#author`,
        "url": `${config.public.baseUrl}/news/author/${options.authorSlug}/`,
        "name": options.author
      },
      {
        "@type": "WebPage",
        "@id": `${config.public.baseUrl}/news/${options.slug}/#webpage`,
        "url": `${config.public.baseUrl}/news/${options.slug}/`,
        "name": options.title,
        "description": options.description,
        "inLanguage": "en-US",
        "isPartOf": { "@id": `${config.public.baseUrl}/news/#website` },
        "breadcrumb": {
          "@id": `${config.public.baseUrl}/news/${options.slug}/#breadcrumblist`
        },
        "author": {
          "@id": `${config.public.baseUrl}/news/author/${options.authorSlug}/#author`
        },
        "creator": {
          "@id": `${config.public.baseUrl}/news/author/${options.authorSlug}/#author`
        },
        "image": {
          "@type": "ImageObject",
          "url": options.featuredImageUrl
        },
        "primaryImageOfPage": {
          "@id": options.featuredImageUrl
        },
        "datePublished": options.datePublished,
        "dateModified": options.dateModified
      },
      {
        "@type": "WebSite",
        "@id": `${config.public.baseUrl}/news/#website`,
        "url": `${config.public.baseUrl}/news/`,
        "name": title,
        "description": description,
        "inLanguage": "en-US",
        "publisher": { "@id": `${config.public.baseUrl}/news/#blog` }
      }
    ]
  };
};

export { homePageLDJson, postListPageLDJson, postPageLDJson };

export type { IPostListPageLDJson, IPostPageLDJson };
