import { ISeo, IPageNumberMetaInfo } from "~/types/contentful/models";

interface ILinkProperty {
  rel: string;
  href: string;
}

export const useSeo = (seo: ISeo) => {
  const route = useRoute();
  const config = useRuntimeConfig();
  const { nofollow, noindex, title, description, keywords, image } = seo;
  const robotsContents: string[] = [];

  const meta = [
    {
      hid: "description",
      property: "description",
      name: "description",
      content: description
    },
    {
      hid: "og:title",
      property: "og:title",
      name: "title",
      content: title
    },
    {
      hid: "og:description",
      property: "og:description",
      name: "description",
      content: description
    },
    {
      hid: "keywords",
      name: "keywords",
      content: Array.isArray(keywords) ? keywords.join(",") : ""
    },
    {
      hid: "og:image",
      property: "og:image",
      name: "image",
      content: image.url
    },
    {
      hid: "og:site_name",
      property: "og:site_name",
      name: "site_name",
      content: "Frontiers Science News"
    },
    {
      hid: "twitter:card",
      property: "twitter:card",
      name: "twitter:card",
      content: "summary_large_image"
    }
  ];

  noindex !== null && robotsContents.push(`${!noindex ? "index" : "noindex"}`);
  nofollow !== null &&
    robotsContents.push(`${!nofollow ? "follow" : "nofollow"}`);

  if (robotsContents.length) {
    meta.push({
      hid: "robots",
      name: "robots",
      content: robotsContents.join(", ")
    });
  }

  const links = [
    { rel: "canonical", href: `${config.public.baseUrl}${route.path}` }
  ];
  if (seo.pageNumberMetaInfo) {
    links.push(
      ...setPaginationMetas(
        seo.pageNumberMetaInfo,
        `${config.public.baseUrl}${route.path}`
      )
    );
  }

  const setPageMetaInfo = () => {
    useHead({
      title: title,
      meta,
      link: links
    });
  };

  return {
    setPageMetaInfo
  };

  function setPaginationMetas(
    pageNumberMetaInfo: IPageNumberMetaInfo,
    canonicalUrl: string
  ): ILinkProperty[] {
    const links: ILinkProperty[] = [];

    const url = new URL(canonicalUrl);
    const path = url.pathname;
    const isLastPage =
      pageNumberMetaInfo.totalResults <=
      pageNumberMetaInfo.pageSize * pageNumberMetaInfo.queryPageNumber;

    if (!isLastPage) {
      const nextPageNumber = Math.max(
        pageNumberMetaInfo.queryPageNumber + 1,
        2
      );
      const nextPageUrl = `${path}?page=${nextPageNumber}`;
      links.push({ rel: "next", href: nextPageUrl });
    }

    const isFirstPage = pageNumberMetaInfo.queryPageNumber <= 1;
    if (!isFirstPage) {
      const prevPageNumber = pageNumberMetaInfo.queryPageNumber - 1;
      const prevPageUrl = `${path}?page=${prevPageNumber}`;
      links.push({ rel: "prev", href: prevPageUrl });
    }

    return links;
  }
};
